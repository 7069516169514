import { Container, Box, Grid } from "@mui/material";
import React, { useEffect, useState } from "react";
import { colors } from "../../assets/colors/index";
import BasicChips from "../../components/blogDetailsChip";
import { useLocation, useParams } from "react-router-dom";
import { baseUrl } from "../../axios";
import moment from "moment";
import CalendarTodayIcon from "@mui/icons-material/CalendarToday";
import parse from "html-react-parser";
import BlogServices from "../../api/blog/BlogService";
import "./index.css";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton
} from "react-share";
import AdleaksService from "../../api/adleaks/AdleaksService";
import DOMPurify from "dompurify";
import { Helmet } from "react-helmet";
import Axios from "../../axios";

const BlogDetails = () => {
  const [item, setItem] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const { id } = useParams();

  const sanitizeConfig = {
    ADD_TAGS: ['figure', 'figcaption'],
    ADD_ATTR: ['style'],
    ALLOW_DATA_ATTR: true
  };

  const sanitizedContent = DOMPurify.sanitize(item?.content, sanitizeConfig)

  // const sharingUrl = window.location.href;

  const getBlogById = async () => {
    try {
      const result = await AdleaksService.getBlogById(id);
      if (result.responseCode == 200) {
        setItem(result.data.blogs[0]);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const htmlContent = item?.content;

  // Create a temporary div element to parse the HTML content
  const tempDiv = document.createElement("div");
  tempDiv.innerHTML = htmlContent;
  let oembedTag;
  let oembedUrl;

  if (tempDiv.querySelector("oembed")) {
    oembedTag = tempDiv.querySelector("oembed");
    oembedUrl = oembedTag.getAttribute("url");
  };

  const sharingUrl = `https://adstreet.mangotech-api.com/share/${id}`;
  const sharingUrlForWhatsApp = window.location.href;

  useEffect(() => {
    getBlogById();
  }, []);

  return (
    <>
      <Container>
        <Box component={"article"} pb={"64px"}>
          <Grid container margin={"0 auto"} sx={{ width: "87%" }}>
            {/* Blog details */}
            <Grid item xs={12}>
              <Grid container mt={0} justifyContent={"space-between"} spacing={1}></Grid>
            </Grid>
            {/* Image */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component={"img"}
                    src={baseUrl + item?.image}
                    sx={{
                      width: { xs: "100%" },
                      height: { xs: "auto", sm: "300px", lg: "600px" },
                      backgroundPosition: "center",
                      backgroundSize: "cover",
                      display: "block",
                      margin: "0 auto",
                      mt: 4,
                      objectFit: "contain",
                    }}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={1.8} mt={2}>
              <Grid container>
                <Grid item columnGap={"10px"}>
                  <FacebookShareButton id="facebook-share-btn" url={sharingUrl}>
                    <a className="btn btn-icon btn-facebook fb-title">
                      <i className="bi bi-facebook class-two"></i>
                      <span className="class-three">Facebook</span>
                    </a>
                  </FacebookShareButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={1.6} mt={2}>
              <Grid container>
                <Grid item>
                  <TwitterShareButton url={sharingUrl}>
                    <a class="btn btn-icon btn-twitter">
                      <i class="fa fa-twitter"></i>
                      <span>Twitter</span>
                    </a>
                  </TwitterShareButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={1.7} mt={2}>
              <Grid container>
                <Grid item>
                  <LinkedinShareButton url={sharingUrl}>
                    <a class="btn btn-icon btn-linkedin">
                      <i class="bi bi-linkedin"></i>
                      <span>LinkedIn</span>
                    </a>
                  </LinkedinShareButton>
                </Grid>
              </Grid>
            </Grid>
            <Grid item xs={6} sm={3} md={3} mt={2}>
              <Grid container>
                <Grid item>
                  <WhatsappShareButton url={sharingUrlForWhatsApp} title={item?.title}>
                    <a class="btn btn-icon btn-whatsapp">
                      <i class="bi bi-whatsapp"></i>
                      <span>WhatsApp</span>
                    </a>
                  </WhatsappShareButton>
                </Grid>
              </Grid>
            </Grid>
            {/* Title */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={12}>
                  <Box
                    component={"h1"}
                    sx={{
                      m: 0,
                      mt: 4,
                      width: "100%",
                      fontSize: { xs: "24px", md: "32px" },
                    }}
                  >
                    {item?.title}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
            {/* <Box id="social-platforms"></Box> */}
            <Grid item xs={12}>
              <Grid container>
                <Grid item xs={2} mt={2}>
                  <Grid container>
                    <Grid item>
                      <CalendarTodayIcon sx={{ fontSize: "17px" }} />
                    </Grid>
                    <Grid item xs={4}>
                      <BasicChips
                        sx={{
                          width: "100px",
                          backgroundColor: "red",
                          display: "block",
                          fontSize: "15px",
                        }}
                        content={moment(item?.date).format("LL")}
                      />
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item xs={12}>
                  {/* {item?.content && (
                    <Box
                      component={"p"}
                      sx={{
                        fontSize: { xs: "10px", md: "17px" },
                        color: colors.cardTitleColor,
                        pb: 5,
                      }}
                      className="parsed-html"
                    >
                      {parse(item?.content)}
                    </Box>
                  )} */}
                  <div className="ck-content" dangerouslySetInnerHTML={{ __html: sanitizedContent }} />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Container >
    </>
  );
};

export default BlogDetails;
